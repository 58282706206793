.footer {
    padding: 0.5rem 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    color: $white;
    height: 100%;
}

.footer-support-email {
    color: $white;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    transition: all 0.3s ease-in-out;
}

.footer-support-email:hover {
    color: $white;
    text-decoration: none;
}

.footer-menu {
    display: inline-flex;
    flex-direction: row;
}
.footer-menu-item {
    display: inline-flex;
    text-decoration: none;
    margin: 0 0.25rem;
}

.footer-menu-item:last-child {
    margin: 0;
}

.footer-menu-item a {
    color: $white;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    transition: all 0.3s ease-in-out;
}

.footer-menu-item a:hover {
    color: $white;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        font-size: 0.75rem;
    }

    .footer-menu {
        padding: 0;
    }

    .footer-menu-item {
        margin: 0 0.5rem;
    }
}
