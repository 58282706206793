.hero-logout-btn {
    background-color: transparent;
    outline: 0;
    border: 1px solid rgba($color: $white, $alpha: 0.55);
    padding: 0.5rem 2rem;
    margin: 0 1rem 0 0;
    border-radius: 50rem;
    color: rgba($color: $white, $alpha: 0.55);
    text-decoration: none;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.hero-logout-btn:hover {
    background-color: $white;
    border: 1px solid $white;
    color: $primary-dark-bg;
}

.navbar-toggler-icon {
    // background-image: url('../src/images/alignRight.svg') !important;
    border-color: none !important;
}

ul.me-auto.navbar-nav {
    margin: 0 auto;
}

.btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 3px;
}

.btn-success {
    color: $white;
    background-color: transparent;
    background-image: $light-pink-gradient;
    border-color: $orange;
}

.btn-success:disabled,
.btn-success.disabled {
    color: $white;
    background-color: transparent;
    background-image: $light-pink-gradient;
    border-color: $orange;
}

.btn-secondary {
    color: $white;
    background-color: $gray-alternate-bg;
    border-color: $gray-alternate-bg;
}

.btn-secondary:focus {
    color: $white;
    background-color: $gray-alternate-bg;
    border-color: $gray-alternate-bg;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: $secondary-dark-bg;
    box-shadow: none;
    color: $white;
}

.accordion-item {
    background-color: $secondary-dark-bg;
    border: 1px solid $secondary-dark-bg;
    color: $white;
}

.accordion-body {
    padding: 1rem;
    font-size: 0.875rem;
    color: $white;
}

.accordion-button:focus {
    border-color: $secondary-dark-bg;
    outline: 0;
    box-shadow: none;
}

.accordion-button {
    padding: 0.875rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: $white;
    background-color: $secondary-dark-bg;
    border: 0;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(-45deg, #ee7752, #e73c7e);
    border-color: #ee7752;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: $purple;
    border-color: $purple;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: $purple;
    border-color: $purple;
    box-shadow: none;
}

.google-sign-in {
    background-image: url('../assets/btn_google_signin_dark_normal_web@2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 191px;
    height: 46px;
    border: none;
    background-color: transparent;
    outline: 0;
}

.github-sign-in {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 186px;
    height: 42px;
    border: none;
    background-color: $primary-dark-bg;
    outline: 0;
    color: $white;
    border-radius: 4px;
}

.collapse-btn {
    cursor: pointer;
    border-radius: 0.25rem;
    height: 24px;
    width: 24px;
    transition: all 0.3s ease-in-out;
}

.collapse-btn:hover {
    background-color: $purple;
}
