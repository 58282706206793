.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    width: 100%;
    font-size: 0.875rem;
    min-height: 64px;
}
.navbar {
    width: 100%;
}

.x-nav-link {
    display: flex;
    gap: 0.5rem;
    justify-content: stretch;
    align-items: center;
}

.menu {
    display: inline-flex;
    list-style: none;
}

.menu-item a {
    color: $white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
}

.menu-item a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
    .header {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 0.875rem;
    }
}

// Header Avatar
.header-avatar {
    height: 32px;
    width: 32px;
    background-color: $white;
    border-radius: 25rem;
    display: flex;
    font-weight: bold;
    font-size: 1rem;
    color: #12141d;
    justify-content: center;
    position: relative;
    align-items: center;
}

.header-is-premium-icon {
    position: absolute;
    right: -4px;
    bottom: -4px;
}

.dropdown-menu-dark {
    color: $white;
    background-color: $primary-dark-bg;
    border-color: $primary-border;
}
