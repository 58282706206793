.filter-container {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
}

.filter-title {
    font-size: 1rem;
    color: rgba($color: $white, $alpha: 0.88);
}

.filter-option-holder {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.25rem;
}

.filter-option-icon {
    display: inline-flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $white, $alpha: 0.1);
    border-radius: 0.25rem;
    transition: all 0.3s ease-in-out;
}

.button-hover:hover {
    cursor: pointer;
    color: $white;
    background-color: $purple;
}

.button-hover {
    color: rgba($color: $white, $alpha: 0.4);
}

.filter-option-icon-opacity {
    opacity: 0.4;
}
.filter-option-dropdown {
    width: 100%;
    flex: 1;
    background-color: rgba($color: $white, $alpha: 0.1);
    color: rgba($color: $white, $alpha: 0.88);
    border: 0;
    outline: 0;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
}

.filter-option-dropdown option {
    color: #2a2c34;
}
// Filter Reset Button
.filter-option-refresh-holder {
    border: 0;
    outline: 0;
    display: flex;
    height: 2rem;
    width: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    color: $white;
    background-color: #2a2c34;
}
