.online-status-holder {
    border: 1px solid $primary-border;
    background-color: $secondary-dark-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 32px;
}

.run-query-btn {
    height: 42px;
    background-color: $purple;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    color: $white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border: none;
    outline: 0;
    padding: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.run-query-btn:hover {
    padding: 0 2rem;
    text-decoration: none;
    color: $white;
    // background-color: #3f51b5;
}

.run-query-test-button {
    height: 42px;
    background-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    color: $white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border: none;
    outline: 0;
    padding: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.run-query-test-button:hover {
    padding: 0 2rem;
    text-decoration: none;
    color: $white;
    background-color: $pink;
}

.report-bug-btn {
    font-size: 0.75rem;
    text-decoration: none;
    color: $white;
    opacity: 0.5;
}

.report-bug-btn:hover {
    color: $white;
    opacity: 1;
}

.connection-status-connected {
    font-weight: 500;
    font-size: 0.75rem;
    color: rgba($color: $white, $alpha: 0.8);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

// SQL Editor Background
.ͼo {
    color: $white;
    background-color: $secondary-dark-bg;
}

.ͼq {
    color: $purple;
}

.ͼp {
    color: $alt-green;
}

.ͼu {
    color: $dark-bg-text;
}

.ͼo .cm-gutters {
    background-color: $secondary-dark-bg;
    color: $dark-bg-text;
    border: none;
}

// SQL Editor Active Line Background
.ͼo .cm-activeLine {
    background-color: $secondary-dark-bg;
}

.ͼo .cm-activeLineGutter {
    background-color: $secondary-dark-bg;
}

.CodeMirror-wrap pre {
    word-break: break-word;
}

.ͼ1.cm-editor {
    font-size: 14px;
    border: 1px solid $primary-border;
}
