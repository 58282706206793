.problem-title {
    font-size: 1.5rem;
    color: $white;
    margin: 1rem 0 1rem 0;
    max-width: 400px;
    font-family: $title-font-family;
    font-size: $primary-heading-fs;
    line-height: 40px;
}
.problem-meta-holder {
    opacity: 0.5;
    font-size: 1rem;
}

.problem-meta-holder .metric-space {
    line-height: 1rem;
    display: inline-flex;
    align-items: center;
}

.problem-meta-holder svg {
    margin-right: 0.5rem;
}

.problem-desc {
    margin: 2rem 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 28px;
    max-width: 400px;
    color: $white;
}

.problem-tip {
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.6;
    color: $white;
}

.problem-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    margin: 1rem 0 0 0;
}
.seen-at-companies {
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}
.company {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
}

.company[data-company='meta'] {
    color: #3482f8;
}
.company[data-company='uber'] {
    color: #909aa8;
}
.company[data-company='twitter'] {
    color: #2dcdff;
}
.company[data-company='airbnb'] {
    color: #f93191;
}
.company[data-company='others'] {
    color: $white;
}
.sql-table-name {
    margin: 1rem 0 0 0;
    font-size: 1rem;
}
.schema-table {
    outline: none;
    margin-top: 0.5rem;
    font-family: monospace;
    text-align: left;
    color: $white;

    border: 0;
    border: 1px solid $primary-border;
    width: 100%;
}
.schema-table th {
    font-family: 'Roboto', sans-serif;
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
}

.schema-table td {
    padding: 0.5rem 0.5rem;
    font-size: 14px;
}

.schema-table tr {
    transition: all 0.3s ease-in-out;
}

.schema-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05);
}

.schema-table tr:hover {
    text-shadow: 0px 2px rgba(0, 0, 0, 0.95);
    background-color: rgba(255, 255, 255, 0.15);
}

// Problem Tags
.problem-tags-holder {
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 4px;
}

.problem-tag {
    text-transform: capitalize;
    background: lightgray;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
}

.previewTableButton {
    outline: none;
    border: none;
    padding: 0.25rem;
    background-color: transparent;
    color: #f7f7f7;
    margin-right: 0.25rem;
}
