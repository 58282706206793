.problem-overview-table {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.88);
    font-weight: 500;
    max-width: 50%;
}

thead {
    background-color: #282c34;
}
.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: transparent;
    border-bottom-width: 0;
    box-shadow: none;
}

.table > thead {
    vertical-align: bottom;
    border: 1px solid #282c34;
}
tbody {
    background-color: rgba(255, 255, 255, 0.025);
    border: 1px solid #2e3038;
    color: rgba(255, 255, 255, 0.8);
}

th {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.6);
}

tbody td {
    padding: 0.15rem 1rem !important;
    opacity: 0.88;
}

.table > :not(:first-child) {
    border-top: none;
}

.table-header {
    background-color: #2c313a;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
}
