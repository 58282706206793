.alert-success {
    color: $white;
    background-color: $blue;
    border-color: $blue;
}

.alert-danger {
    color: $white;
    background-color: #f72f52;
    border-color: #f72f52;
}
.alert {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
