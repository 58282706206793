.filter-header {
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
}

.filter-holder {
    display: flex;
    flex-direction: column;
}

.filter-select {
    outline: none;
    border: 0;
    background-color: rgba(255, 255, 255, 0.06);
    color: rgba(255, 255, 255, 0.75);
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.875rem;
    border-radius: 6px;
}

.question-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.question {
    border-radius: 6px;
    text-decoration: none;
    padding: 12px 16px;
    margin-top: 16px;
    background: $secondary-dark-bg;
    flex: 370px 0;
    transition: all 0.3s ease-in-out;
    display: flex;
}

.solved {
    color: $alt-green;
}

.question:hover {
    transform: scale(1.05);
    cursor: pointer;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.05)
    );
}

.question-title-holder {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.question_title {
    margin: 0 0 0.5rem 0;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.isPremiumLock {
    stroke: #fbb836;
    position: absolute;
    left: 24px;
    background-color: #191c22;
    border-radius: 4px;
    top: 24px;
    padding: 4px;
}

.question_meta-holder {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.75rem;
}
.metric-space {
    margin-right: 0.875rem;
}

.question-metric {
    font-size: 13px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.6);
}

.question-metric-icon {
    margin-right: 4px;
}
.isSolvedIcon {
    color: $alt-green;
}

@media screen and (max-width: 1024px) {
    .question-list {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0 1rem;
    }

    .question {
        flex-grow: 1;
    }
}

.question-logo {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
}
