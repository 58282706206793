$white: #f7f7f7;

// Typography
$primary-heading-fs: 32px;
$secondary-heading-fs: 20px;
$body-font-fs: 15px;

$title-font-family: 'Oswald', sans-serif;
$copy-font-family: 'Roboto', sans-serif;

// Brand Colors
$primary-dark-bg: #0d1219;
$secondary-dark-bg: #191c22;
$gray-alternate-bg: #22262c;

// Linear Gradients
$light-pink-gradient: linear-gradient(-45deg, #ee7752, #e73c7e);
$light-blue-gradient: linear-gradient(58deg, #0071ff, #5e6ad2);
// Border Color
$primary-border: rgba(255, 255, 255, 0.1);

// Text Colors
$muted-text: rgb(34 38 44 / 65%);
$dark-bg-text: #9396a2;

// Theme Colors
$pink: #e73c7e !important;
$blue: #3266e6 !important;
$purple: #9a5dff !important;
$orange: #ee7752 !important;
$alt-green: #42ffbc !important;
$green: #78be06 !important;

:root {
    --bs-primary: $blue;
    --bs-secondary: $gray-alternate-bg;
    --bs-success: $green;
    --bs-info: $blue;
    --bs-warning: $orange;
    --bs-danger: $pink;
    --bs-light: $white;
    --bs-dark: $primary-dark-bg;
}
