.difficulty-badge {
    font-size: 10px;
    padding: 2px 1rem;
    border-radius: 4px;
    margin-right: 0.5rem;
    font-weight: 700;
    text-shadow: 1px 1px rgb(0 0 0 / 25%);
    text-transform: capitalize;
}

[data-difficulty='easy'] {
    background-color: $green;
}

[data-difficulty='med'] {
    background-color: #fd7e14;
}

[data-difficulty='hard'] {
    background-color: #f72e52;
}

.difficulty-badge-sm {
    text-align: center;
    padding: 2px 12px;
}

.difficulty-badge-lg {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
}
